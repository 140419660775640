import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Lang from "../images/lang.inline.svg";
import LogoMain from "../images/logo.inline.svg";
import { Dropdown, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./header.css";

function Header({ htmllang, langswitcher }) {
  var currentLang = htmllang;
  var switcherLink = langswitcher;
  if (currentLang === "en-us") {
    return (
      <header>
        <Container>
          <div className="logo">
            <Link to="/">
              <LogoMain />
            </Link>
          </div>
          <nav className="navbar">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/dagcoin-wallet/">
                    Wallet
                  </Link>
                </li>
                <li className="nav-item active">
                  <Link className="nav-link" to="/ecosystem/">
                    Ecosystem
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog/">
                    News & Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sponsorships/">
                    Sponsorship
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="btn-no-style"
                    >
                      Info <div className="triangle"></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="https://merchant.dagcoin.org/">
                        Find merchants
                      </Dropdown.Item>
                      <Dropdown.Item href="https://prismic-io.s3.amazonaws.com/dagcoin/f4e531e1-a5db-43b6-930c-14bf705e65ee_Dagcoin_White_Paper.pdf">
                        Whitepaper
                      </Dropdown.Item>
                      <Dropdown.Item href="https://explorer.dagcoin.link/">
                        Dagcoin Explorer
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/ecosystem-timeline/">
                          Ecosystem timeline
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/timeline/">Timeline</Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/distribution/">Distribution</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </nav>

          <Dropdown className="mobile-menu">
            <Dropdown.Toggle
              variant="mobile"
              id="dropdown-basic"
              className="mobile-menu-btn"
            >
              <span></span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mobile-menu-wrap navbar">
              <ul id="nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/dagcoin-wallet/">
                    Wallet
                  </Link>
                </li>

                <li className="nav-item active">
                  <Link className="nav-link" to="/ecosystem/">
                    Ecosystem
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog/">
                    News & Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sponsorships/">
                    Sponsorship
                  </Link>
                </li>
                <li className="nav-item dropdown mobilesubmenu">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="btn-no-style"
                    >
                      Info <div className="triangle"></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="https://merchant.dagcoin.org/">
                        Find merchants
                      </Dropdown.Item>
                      <Dropdown.Item href="https://prismic-io.s3.amazonaws.com/dagcoin/f4e531e1-a5db-43b6-930c-14bf705e65ee_Dagcoin_White_Paper.pdf">
                        Whitepaper
                      </Dropdown.Item>
                      <Dropdown.Item href="https://explorer.dagcoin.link/">
                        Dagcoin Explorer
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/ecosystem-timeline/">
                          Ecosystem timeline
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/timeline/">Timeline</Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/distribution/">Distribution</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </header>
    );
  } else if (currentLang === "et-ee") {
    return (
      <header>
        <Container>
          <div className="logo">
            <Link to="/et">
              <LogoMain />
            </Link>
          </div>
          <nav className="navbar">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/et">
                    Avaleht
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/et/rahakott">
                    Rahakott
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/et/dagcoini-hind">
                    Mündid
                  </Link>
                </li>
                <li className="nav-item active">
                  <Link className="nav-link" to="/et/okosusteem">
                    Ökosüsteem
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/et/blogi/">
                    Uudised ja blogi
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/et/toetused/">
                    Toetused
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="btn-no-style"
                    >
                      Teave <div className="triangle"></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="https://merchant.dagcoin.org/">
                        Leia kaupmehi
                      </Dropdown.Item>
                      <Dropdown.Item href="https://prismic-io.s3.amazonaws.com/dagcoin/f4e531e1-a5db-43b6-930c-14bf705e65ee_Dagcoin_White_Paper.pdf">
                        White paper
                      </Dropdown.Item>
                      <Dropdown.Item href="https://explorer.dagcoin.link/">
                        Dagcoin Explorer
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/et/okosusteemi-ajatelg/">
                          Ökosüsteemi ajatelg
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/et/tehniline-ajatelg/">
                          Tehniline ajatelg
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/et/jaotamine/">Dagcoin’ide jaotus</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </nav>

          <Dropdown className="mobile-menu">
            <Dropdown.Toggle
              variant="mobile"
              id="dropdown-basic"
              className="mobile-menu-btn"
            >
              <span></span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mobile-menu-wrap navbar">
              <ul id="nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="/et">
                    Avaleht
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/et/rahakott">
                    Rahakott
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/et/dagcoini-hind">
                    Mündid
                  </Link>
                </li>
                <li className="nav-item active">
                  <Link className="nav-link" to="/et/okosusteem">
                    Ökosüsteem
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/et/blogi/">
                    Uudised ja blogi
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/et/toetused/">
                    Toetused
                  </Link>
                </li>
                <li className="nav-item dropdown mobilesubmenu">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="btn-no-style"
                    >
                      Teave <div className="triangle"></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="https://merchant.dagcoin.org/">
                        Leia kaupmehi
                      </Dropdown.Item>
                      <Dropdown.Item href="https://prismic-io.s3.amazonaws.com/dagcoin/f4e531e1-a5db-43b6-930c-14bf705e65ee_Dagcoin_White_Paper.pdf">
                        White paper
                      </Dropdown.Item>
                      <Dropdown.Item href="https://explorer.dagcoin.link/">
                        Dagcoin Explorer
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/et/okosusteemi-ajatelg/">
                          Ökosüsteemi ajatelg
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/et/tehniline-ajatelg/">
                          Tehniline ajatelg
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/et/jaotamine/">Dagcoin’ide jaotus</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </header>
    );
  } else {
    return (
      <header>
        <Container>
          <div className="logo">
            <Link to="/">
              <LogoMain />
            </Link>
          </div>
          <nav className="navbar">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/dagcoin-wallet/">
                    Wallet
                  </Link>
                </li>

                <li className="nav-item active">
                  <Link className="nav-link" to="/ecosystem/">
                    Ecosystem
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog/">
                    News & Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sponsorships/">
                    Sponsorship
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="btn-no-style"
                    >
                      Info <div className="triangle"></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="https://merchant.dagcoin.org/">
                        Find merchants
                      </Dropdown.Item>
                      <Dropdown.Item href="https://prismic-io.s3.amazonaws.com/dagcoin/f4e531e1-a5db-43b6-930c-14bf705e65ee_Dagcoin_White_Paper.pdf">
                        Whitepaper
                      </Dropdown.Item>
                      <Dropdown.Item href="https://explorer.dagcoin.link/">
                        Dagcoin Explorer
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/ecosystem-timeline/">
                          Ecosystem timeline
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/timeline/">Timeline</Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/distribution/">Distribution</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                {/* <li className="nav-item blue-menu-item">
                  <Link className="nav-link" to="/getting-dagcoins/">
                    <DagWhite />
                    Get dagcoins
                  </Link>
                </li> */}
              </ul>
            </div>
          </nav>

          <Dropdown className="mobile-menu">
            <Dropdown.Toggle
              variant="mobile"
              id="dropdown-basic"
              className="mobile-menu-btn"
            >
              <span></span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mobile-menu-wrap navbar">
              <ul id="nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/dagcoin-wallet/">
                    Wallet
                  </Link>
                </li>

                <li className="nav-item active">
                  <Link className="nav-link" to="/ecosystem/">
                    Ecosystem
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog/">
                    News & Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sponsorships/">
                    Sponsorship
                  </Link>
                </li>
                <li className="nav-item dropdown mobilesubmenu">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="btn-no-style"
                    >
                      Info <div className="triangle"></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="https://merchant.dagcoin.org/">
                        Find merchants
                      </Dropdown.Item>
                      <Dropdown.Item href="https://prismic-io.s3.amazonaws.com/dagcoin/f4e531e1-a5db-43b6-930c-14bf705e65ee_Dagcoin_White_Paper.pdf">
                        Whitepaper
                      </Dropdown.Item>
                      <Dropdown.Item href="https://explorer.dagcoin.link/">
                        Dagcoin Explorer
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/ecosystem-timeline/">
                          Ecosystem timeline
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/timeline/">Timeline</Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="sublinksheader">
                        <Link to="/distribution/">Distribution</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                {/* <li className="nav-item blue-menu-item">
                  <Link className="nav-link" to="/getting-dagcoins/">
                    <DagWhite />
                    Get dagcoins
                  </Link>
                </li> */}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </header>
    );
  }
}
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
