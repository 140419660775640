import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { Swiper, SwiperSlide } from "swiper/react";
import GetDagcoins from "../components/get-dagcoins";
import Header from "../components/header";
import Layout from "../components/layout";
import Modal from "../components/modal";
import Price from "../components/price-api";
import { Seo } from "../components/seo";
import Arrowrightinline from "../images/arrow-right.inline.svg";
import BuildIt from "../images/buildit.inline.svg";
import CooP from "../images/Cooperate.inline.svg";
import Dagcoinconnect from "../images/dagcoinconnect.inline.svg";
import ElementBg from "../images/elementbg.inline.svg";
import IconShadow from "../images/dagcoiniconshadow.inline.svg";
import InterGate from "../images/Integrate.inline.svg";
import LogoFour from "../images/logo4.inline.svg";
import LogoOne from "../images/logo1.inline.svg";
import LogoThree from "../images/logo3.inline.svg";
import LogoTwo from "../images/logo2.inline.svg";
import Merchant from "../images/merchant.inline.svg";
import Paperinline from "../images/paper.inline.svg";
import "../components/index.css";
import "swiper/swiper.scss";

function IndexPage({ data }) {
  var featuredPost = data.allPrismicBlog.edges;
  var SeoMeta = data.prismicHome.data;
  if (data.prismicHome.lang === "en-us") {
    var currentUrl = "/";
  } else if (data.prismicHome.lang === "et-ee") {
    currentUrl = "/et/";
  } else {
    currentUrl = "/";
  }

  if (data.prismicHome.lang === "en-us") {
    var NextUrl = "/et/";
  } else if (data.prismicHome.lang === "et-ee") {
    NextUrl = "/";
  } else {
    NextUrl = "wrong lang";
  }
  return (
    <>
      <Header htmllang={data.prismicHome.lang} langswitcher={NextUrl} />
      <Layout htmllang={data.prismicHome.lang}>
        <div className="container-wrap-home">
          <Container as="section" className="abovehome fullwidth">
            <StaticImage
              src="../../src/images/homebg.jpg"
              alt="A background"
              layout="fullWidth"
            />
            <div className="flex" role="button">
              <Row className="homevideo"></Row>

              <Row>
                <PrismicRichText field={SeoMeta.above_fold_title.richText} />
              </Row>
              <Row className="above-btns">
                <Link
                  className="white-btn"
                  to={SeoMeta.second_button_link_above.text}
                >
                  Download wallet
                </Link>
              </Row>
            </div>
          </Container>
          <Container as="section" className="fullwidth afterabove">
            <StaticImage
              src="../../src/images/road.jpg"
              alt="A road"
              layout="fullWidth"
            />
            <Row>
              <Col lg={2}>
                <IconShadow />
              </Col>
              <Col lg={10}>
                <h2>{SeoMeta.after_above_header.text}</h2>
                <p>{SeoMeta.after_above_content.text}</p>
              </Col>
            </Row>
          </Container>
          <Container as="section" className="fullwidth fourrows">
            <Row>
              <Col lg={3}>
                <LogoOne />
                <h4>{SeoMeta.first_title__four_sections_.text}</h4>
                <p>{SeoMeta.first_desc__four_sections.text}</p>
              </Col>
              <Col lg={3}>
                <LogoTwo />
                <h4>{SeoMeta.second_title__four_sections_.text}</h4>
                <p>{SeoMeta.second_desc__four_sections.text}</p>
              </Col>
              <Col lg={3}>
                <LogoThree />
                <h4>{SeoMeta.third_title__four_sections_.text}</h4>
                <p>{SeoMeta.third_desc__four_sections.text}</p>
              </Col>
              <Col lg={3}>
                <LogoFour />
                <h4>{SeoMeta.fourth_title__four_sections_.text}</h4>
                <p>{SeoMeta.fourth_desc__four_sections.text}</p>
              </Col>
            </Row>
          </Container>
          <Container as="section" className="fullwidth merchantfinder">
            <div className="whitebghome">
              <StaticImage
                src="../../src/images/bg.png"
                alt="A white background"
                layout="fullWidth"
              />
            </div>
            <div className="mapbg">
              <StaticImage
                src="../../src/images/map-bg.png"
                alt="A map"
                layout="fullWidth"
              />
            </div>
            <Row>
              <div className="maptwo">
                <p>1000+</p>
                <StaticImage
                  src="../../src/images/map-2.png"
                  alt="A map"
                  layout="fullWidth"
                />
              </div>
              <Col
                lg={{ span: 5, offset: 7 }}
                xl={{ span: 6, offset: 6 }}
                className="merchant-text-area"
              >
                <Merchant />
                <h3>{SeoMeta.merchant_title.text}</h3>
                <PrismicRichText field={SeoMeta.merchant_desc.richText} />
                <div className="merchantbtn">
                  <a
                    rel="noreferrer"
                    href="https://merchant.dagcoin.org/"
                    className="discoverbtn"
                    target="_blank"
                  >
                    <StaticImage
                      src="../../src/images/location.png"
                      alt="A location"
                      layout="constrained"
                    />
                    {SeoMeta.merchant_first_button.text}
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://merchant.dagcoin.org/sign-up"
                    className="openbusiness"
                    target="_blank"
                  >
                    {SeoMeta.merchant_second_button.text}
                    <Arrowrightinline />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <Container as="section" className="members fullwidth">
            <Row>
              <h4>{SeoMeta.members_count.text}</h4>
              <p>{SeoMeta.members_desc.text}</p>
            </Row>
          </Container>
          {/* <Container as="section" className="subscription">
            <div className="join-text">
              <div className="home-blue">
                <HomeBlue />
              </div>
              <div className="home-phones">
                <StaticImage
                  src="../../src/images/phoneshome.png"
                  alt="A phone"
                  layout="constrained"
                />
              </div>
              <PrismicRichText field={SeoMeta.join_community.richText} />
              <div className="join-form">
                <NetlifyForm name="Newsletter">
                  {({ loading, error, success }) => (
                    <div className="order-form">
                      {loading && <div className="formloading">Loading...</div>}
                      {error && (
                        <div className="success-fail">
                          {SeoMeta.error_message.text}
                        </div>
                      )}
                      {success && (
                        <div className="success-join">
                          {SeoMeta.success_message.text}
                        </div>
                      )}
                      {!loading && !success && (
                        <React.Fragment>
                          <div className="newsletter-content">
                            <label>
                              <input
                                className="newslettermail"
                                type="email"
                                name="email"
                                required
                                placeholder={SeoMeta.yourmail.text}
                              />
                            </label>
                            <button type="submit" className="sendjoin">
                              <span>
                                {SeoMeta.send_invite.text}
                                <StaticImage
                                  src="../../src/images/right-arrow-news.png"
                                  alt="An arrow"
                                  layout="constrained"
                                />
                              </span>
                            </button>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </NetlifyForm>
              </div>
            </div>
          </Container> */}

          <Container as="section">
            <div className="dagcoin-price-data dag-support">
              <StaticImage
                src="../../src/images/girl.png"
                alt="A girl"
                layout="fullWidth"
              />
              <div className="dag-data-wrap">
                <PrismicRichText field={SeoMeta.quickspec.richText} />
                <table cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          {
                            data.prismicHome.data.front_page_data_table[0]
                              .left_title.text
                          }
                        </span>
                      </td>
                      <td>
                        {
                          data.prismicHome.data.front_page_data_table[0]
                            .right_description.text
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          {
                            data.prismicHome.data.front_page_data_table[2]
                              .left_title.text
                          }
                        </span>
                      </td>
                      <td>
                        {
                          data.prismicHome.data.front_page_data_table[2]
                            .right_description.text
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          {
                            data.prismicHome.data.front_page_data_table[3]
                              .left_title.text
                          }
                        </span>
                      </td>
                      <td>
                        {
                          data.prismicHome.data.front_page_data_table[3]
                            .right_description.text
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          {
                            data.prismicHome.data.front_page_data_table[5]
                              .left_title.text
                          }
                        </span>
                      </td>
                      <td>
                        {
                          data.prismicHome.data.front_page_data_table[5]
                            .right_description.text
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          {
                            data.prismicHome.data.front_page_data_table[6]
                              .left_title.text
                          }
                        </span>
                      </td>
                      <td className="max-coin-dis-w">
                        {
                          data.prismicHome.data.front_page_data_table[6]
                            .right_description.text
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          {
                            data.prismicHome.data.front_page_data_table[7]
                              .left_title.text
                          }
                        </span>
                      </td>
                      <td>
                        {
                          data.prismicHome.data.front_page_data_table[7]
                            .right_description.text
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          {
                            data.prismicHome.data.front_page_data_table[8]
                              .left_title.text
                          }
                        </span>
                      </td>
                      <td>
                        {
                          data.prismicHome.data.front_page_data_table[8]
                            .right_description.text
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          {
                            data.prismicHome.data.front_page_data_table[9]
                              .left_title.text
                          }
                        </span>
                      </td>
                      <td>
                        <a href="https://www.lbank.com/" target="_blank">
                          LBank
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <a
                  href="https://prismic-io.s3.amazonaws.com/dagcoin/f4e531e1-a5db-43b6-930c-14bf705e65ee_Dagcoin_White_Paper.pdf"
                  className="whitepaper"
                >
                  <Paperinline />
                  {SeoMeta.read_the_whitepaper.text}
                </a>
              </div>
            </div>
          </Container>
          <Container as="section" className="growthrow">
            <StaticImage
              src="../../src/images/bg2.png"
              alt="A background"
              layout="fullWidth"
            />
            <div className="growthwrap">
              <PrismicRichText field={SeoMeta.steps_to_grow.richText} />
            </div>
            <Row className="growth-wrap">
              <Col>
                <div className="growth-content">
                  <BuildIt />
                  <PrismicRichText
                    field={SeoMeta.create_an_ecosystem.richText}
                  />
                </div>
              </Col>
              <Col>
                <div className="growth-content">
                  <InterGate />
                  <PrismicRichText
                    field={SeoMeta.build_the_community.richText}
                  />
                </div>
              </Col>
              <Col>
                <div className="growth-content">
                  <CooP />
                  <PrismicRichText
                    field={SeoMeta.scale___co_operate.richText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <Container as="section" className="homepage-blog">
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                900: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 4,
                },
                1500: {
                  slidesPerView: 5,
                },
                2800: {
                  slidesPerView: 8,
                },
              }}
              autoPlay={{
                delay: 3500,
              }}
              spaceBetween={40}
              slidesPerView={1}
            >
              {featuredPost.slice(0, 8).map((value, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Link to={currentUrl + value.node.uid}>
                      <div className="slider-thumb">
                        <GatsbyImage
                          alt="Dagcoin"
                          image={value.node.data.featured_image.gatsbyImageData}
                        />
                      </div>
                      <div className="slider-text-area">
                        <h3>{value.node.data.title.text}</h3>
                        <p>{value.node.data.blog_content.text}</p>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Container>
          <section className="homeprefooter"></section>
        </div>
      </Layout>
    </>
  );
}

export const Head = ({ data }) => {
  const { seo_description, seo_image, seo_title } = data.prismicHome.data;
  return (
    <>
      <Seo
        description={seo_description.text}
        image={seo_image.url}
        pathname={data.prismicHome.uid}
        title={seo_title.text}
        // lang={data.prismicHome.lang}
      />
    </>
  );
};
export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicHome(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        front_page_data_table {
          left_title {
            text
          }
          right_description {
            text
          }
        }
        above_fold_title {
          richText
          html
          text
        }
        error_message {
          text
        }
        success_message {
          text
        }
        yourmail {
          text
        }
        send_invite {
          text
        }
        after_above_content {
          text
        }
        after_above_header {
          text
        }
        build_the_community {
          richText
          html
          text
        }
        create_an_ecosystem {
          richText
          html
          text
        }
        discover_ecosystem {
          richText
          html
          text
        }
        discover_ecosystem_button {
          text
        }
        discover_ecosystem_link {
          text
        }
        discover_the_ecosystem {
          richText
          html
          text
        }
        discover_the_ecosystem_button {
          text
        }
        discover_the_ecosystem_link {
          text
        }
        download_wallet {
          richText
          html
          text
        }
        download_wallet_button {
          text
        }
        download_wallet_link {
          text
        }
        first_button_link_above {
          text
        }
        first_button_text_above {
          text
        }
        first_desc__four_sections {
          text
        }
        first_title__four_sections_ {
          text
        }
        fourth_desc__four_sections {
          text
        }
        fourth_title__four_sections_ {
          text
        }
        get_dags {
          richText
          html
          text
        }
        get_dags_button {
          text
        }
        get_dags_link {
          text
        }
        join_community {
          richText
          html
          text
        }
        members_count {
          text
        }
        members_desc {
          text
        }
        merchant_desc {
          richText
          html
          text
        }
        merchant_first_button {
          text
        }
        merchant_second_button {
          text
        }
        quickspec {
          richText
          html
          text
        }
        read_the_whitepaper {
          text
        }
        scale___co_operate {
          richText
          html
          text
        }
        second_button_link_above {
          text
        }
        second_button_text_above {
          text
        }
        second_desc__four_sections {
          text
        }
        second_title__four_sections_ {
          text
        }
        seo_description {
          text
        }
        seo_image {
          url
        }
        seo_title {
          text
        }
        steps_to_grow {
          richText
          html
          text
        }
        third_desc__four_sections {
          text
        }
        third_title__four_sections_ {
          text
        }
        merchant_title {
          text
        }
      }
    }
    allPrismicBlog(
      sort: { fields: data___date, order: DESC }
      limit: 20
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          data {
            blog_content {
              text
            }
            featured_image {
              gatsbyImageData(width: 700, placeholder: BLURRED)
            }
            title {
              text
            }
          }
          uid
        }
      }
    }
  }
`;
export default IndexPage;
